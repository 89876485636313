import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7c3d4470"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "menu" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["id"]
const _hoisted_5 = { class: "class-img" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "class-name" }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { class: "children-item-img" }
const _hoisted_10 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuData, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass(['menu-item', _ctx.active === item.id ? 'select-item' : '']),
          key: index,
          onClick: ($event: any) => (_ctx.changeMenuItem(item))
        }, [
          _createElementVNode("div", {
            class: _normalizeClass([
            'menu-item-name',
            _ctx.active === item.id ? 'select-item-name' : '',
          ])
          }, _toDisplayString(item.menuName), 3)
        ], 10, _hoisted_3))
      }), 128))
    ]),
    _createElementVNode("div", {
      class: "menu-view",
      onScroll: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.scrollTop && _ctx.scrollTop(...args))),
      ref: "menuViewRef"
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.classData, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          id: item.id,
          class: "menu-class",
          key: index
        }, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("img", {
              src: item.mbSecpic,
              alt: ""
            }, null, 8, _hoisted_6)
          ]),
          _createElementVNode("div", _hoisted_7, _toDisplayString(item.menuName), 1),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.childMenus, (cell, idx) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "class-children",
              key: idx
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(cell.childMenus, (sun, idx) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "children-item",
                  key: idx,
                  onClick: ($event: any) => (_ctx.goPage(sun.id))
                }, [
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("img", {
                      src: sun.mbsecondPicture,
                      alt: ""
                    }, null, 8, _hoisted_10)
                  ]),
                  _createElementVNode("div", null, _toDisplayString(sun.menuName), 1)
                ], 8, _hoisted_8))
              }), 128))
            ]))
          }), 128))
        ], 8, _hoisted_4))
      }), 128))
    ], 544)
  ]))
}