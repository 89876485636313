import { onMounted, ref, watch } from 'vue'

/*  函数封装  */
function UseScroll(menuViewRef: any, anchor: any) {
    const total: number = anchor.offsetTop - 50; // 当前元素距离顶部的距离
    let distanceTop: any = menuViewRef.value.scrollTop; // 当前滚动条距离顶部的距离
    const step: number = Math.floor(total / 50) || 10;
    if (total > distanceTop) {
        smoothDown();
    } else {
        smoothUp();
    }

    function smoothDown() {
        if (total > distanceTop) {
            distanceTop += step; // 移动一小段
            if (distanceTop > total) {
                distanceTop = total;
            }
            menuViewRef.value.scrollTop = distanceTop;
            setTimeout(smoothDown, 10);
        } else {
            // 限制滚动停止时的距离
            distanceTop = total;
        }
    }

    function smoothUp() {
        if (distanceTop > total) {
            distanceTop -= step;
            if (distanceTop < total) {
                distanceTop = total;
            }
            menuViewRef.value.scrollTop = distanceTop;
            setTimeout(smoothUp, 10);
        } else {
            distanceTop = total;
        }
    }
}


/* 类封装 */
interface Model {
    scrollView: any, // 轮动的盒子元素
    menuViewRef: any, // 子元素
}


class Usescroll implements Model {
    scrollView: any; // 滚动子元素
    menuViewRef: any; // 滚动的元素
    total: number; // 当前元素距离顶部的距离
    distanceTop: any; // 当前滚动条距离顶部的距离
    step: number;
    timer: any;
    constructor(scrollView: any, menuViewRef: any) {
        this.scrollView = scrollView
        this.menuViewRef = menuViewRef
        this.total = scrollView.offsetTop - 50
        this.distanceTop = menuViewRef.value.scrollTop
        this.step = 0
    }

    init() {
        clearInterval(this.timer)
        if (this.total > this.distanceTop) {
            this.step = Math.floor(this.total / 30)
            this.timer = setInterval(this.smoothDown.bind(this), 10)
        }
        if (this.distanceTop > this.total) {
            this.step = Math.floor((this.distanceTop - this.total) / 30)
            this.timer = setInterval(this.smoothUp.bind(this), 10)
        }
    }

    smoothDown() {
        this.distanceTop += this.step; // 移动一小段
        if (this.distanceTop > this.total) {
            this.distanceTop = this.total;
        }
        this.menuViewRef.value.scrollTop = this.distanceTop;
        if (this.distanceTop === this.total) {
            clearInterval(this.timer)
        }
    }

    smoothUp() {
        if (this.distanceTop > this.total) {
            this.distanceTop -= this.step;
            if (this.distanceTop < this.total) {
                this.distanceTop = this.total;
            }
            this.menuViewRef.value.scrollTop = this.distanceTop;
            if (this.distanceTop === this.total) {
                clearInterval(this.timer)
            }
        }
    }
}

export default Usescroll