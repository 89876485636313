
import { defineComponent, onMounted, reactive, toRefs, ref } from "vue";
import requestAsync from "../../utils/request";
import Usescroll from "../../hooks/useScroll";
import { useRouter } from "vue-router";
export default defineComponent({
  name: "Menu",
  components: {},
  setup() {
    const router = useRouter();
    const data = reactive<any>({
      active: 0,
      menuData: {},
      classData: [],
    });
    const menuViewRef: any = ref(null);
    /* methods */
    onMounted(() => {
      getMenuData();
    });

    const scrollTop = (val: any) => {
      let distanceTop: number = val.target.scrollTop;
      let active = Math.floor(distanceTop / 528);
      data.active = data.classData[active].id;
    };

    const changeMenuItem = (item: any) => {
      let anchor: any = document.getElementById(item.id);
      const usescroll = new Usescroll(anchor, menuViewRef);
      usescroll.init();
    };

    const getMenuData = async () => {
      let menuData: any = await requestAsync({
        // baseIp: "http://192.168.11.22:8085",
        baseIp: "http://over-test.huakai.com",
        url: "/en_SG/js/roseonly_menu.json",
      });
      data.menuData = menuData.childMenus.childMenus;
      data.active = menuData.childMenus.childMenus[0].id;
      data.classData = changeMenuData(data.menuData);
    };

    const changeMenuData = (menuData: any) => {
      let list: Array<any> = [];
      menuData.forEach((item: any) => {
        list.push(item);
      });
      return list;
    };

    const goPage = (id: any): void => {
      router.push({
        name: "List",
        query: {
          id,
        },
      });
    };


    const map = new Map()
    const o = { name: 'o'}
    map.set(o, 'a')
    console.log(map)

    return {
      menuViewRef,
      ...toRefs(data),
      changeMenuItem,
      scrollTop,
      goPage,
    };
  },
});
